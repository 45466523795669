import { Paper } from "@mui/material";
import { getCurrency } from "../../services/tableHelper";

export default function OrderModalProductsList({ totalOrderPrice, orderProducts }: any) {
    const currency = getCurrency();

    return (
        <div>
            {orderProducts.length > 0 && (
                <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr 1fr', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
                    <div style={{ fontSize: '14px' }}>Product Name</div>
                    <div style={{ fontSize: '14px' }}>Unit Price</div>
                    <div style={{ fontSize: '14px' }}>Quantity</div>
                    <div style={{ fontSize: '14px' }}>Total Price</div>
                </div>
            )}
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {
                    orderProducts && orderProducts.map((product: any) => {
                        return (
                            <Paper variant="outlined" key={product.id} style={{ marginBottom: '5px', padding: '10px', display: 'grid', gridTemplateColumns: '2fr 1fr 1fr 1fr', gap: '10px', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <h4 style={{ margin: '0px', fontSize: '14px' }}>{product.name}</h4>
                                </div>
                                <div style={{ fontSize: '14px' }}>{currency} {Number(product.unit_price_at_purchase - product.discount_amount).toFixed(2)}</div>
                                <div style={{ fontSize: '14px' }}>{product.quantity}</div>
                                <div style={{ fontSize: '14px' }}>{currency} {Number(product.total_price).toFixed(2)}</div>
                            </Paper>
                        )
                    })
                }
            </div>
            <div style={{ padding: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }}>
                <div>
                    <h4 style={{ margin: '0px' }}>Total</h4>
                </div>
                <div>
                    <span style={{ fontSize: '14px' }}>{currency} {totalOrderPrice?.toFixed(2)}</span>
                </div>
            </div>
        </div>
    )
}
