import { NotificationKey, NotificationPayload } from "../services/notify";
import { CurrencyCode } from "../types/currencies";

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

type LoginPayload = {
    admin: {
        username: string;
        access_token: string;
        token_type: string;
        timestamp: string;
        is_email_verified: boolean;
        currency: CurrencyCode;
    };
};

export const loginAction = (payload: LoginPayload) => ({
    type: LOGIN,
    payload,
});

export const logout = () => ({
    type: LOGOUT,
});

/**
 * NOTIFICATIONS
 */
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const addNotification = (notification: NotificationPayload) => {
    return {
        type: ADD_NOTIFICATION,
        notification,
    };
};

export const removeNotification = (key: NotificationKey) => {
    return {
        type: REMOVE_NOTIFICATION,
        key,
    };
};