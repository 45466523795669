import axios, { AxiosRequestConfig } from 'axios';
import { loginAction, logout } from '../redux/actions';
import store from '../redux/store';
import { LoginFields, RegisterFields } from '../types';
import notify from './notify';
import { LOGIN } from '../utils/routes';
// import notify from './notify';

export const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});
console.log("environment", process.env.REACT_APP_BASE_URL);

export class ApiClientError extends Error {
	public response: any;

	constructor(error: any) {
		super('Error occurred in api call');
		this.name = 'ApiClientError';
		this.response = error.response;
	}
}

// Interceptors for unauthorized requests (expired tokens)
instance.interceptors.response.use(
	(response) => response,
	(error) => {
		console.error(error.response);
		const isAttemptingLogin = error?.response?.request?.responseURL?.includes(LOGIN);
		if (isAttemptingLogin) {
			return Promise.reject(error);
		}
		if (error.response && error.response.status === 401) {
			clearAuth(true);
		} else {
			const message = error.response && error.response.data && error.response.data.message;
			const extra = error.response && error.response.data && error.response.data.errors;

			notify('error', message, extra);
		}

		return Promise.reject(new ApiClientError(error));
	}
);

// We are going to export a default object with keys that keep our API routes organized.
// For example, all of the auth routes live in the Auth object
const apiClient = {
	auth: {
		login(payload: LoginFields) {
			return instance
				.post('/authenticate', payload)
				.then((response: any) => {
					// Get data from response
					const { data } = response;
					const { user: { firstname, surname, email, shop_hash_id, shop_name, is_email_verified, currency }, token } = data;

					const admin = {
						firstname,
						token,
						shop_hash_id,
						shop_name,
						surname,
						email,
						is_email_verified,
						currency,
						timestamp: new Date().toString(),
					};

					// Create new auth State
					const authState = {
						user: admin,
					};

					if (token) {
						instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
						instance.defaults.headers.common['x-shop'] = shop_hash_id;
					} else {
						console.error('No Access Token found from Response!');
					}
					return authState;
				})
				.then((authState: any) => {
					store.dispatch(
						loginAction(authState)
					);

					// Notify
					notify('success', 'Logged In');

					return authState;
				})
				.catch((error: any) => {
					return Promise.reject(error);
				});
		},
		logout() {
			clearAuth();
		},
		register(payload: RegisterFields) {
			return instance.post('/users/register-user', payload).then((_) => {
				notify('success', 'Registered Successfully');
			});
		},
	},
	// DEFAULT CLIENT CALLS
	get(route: string, config?: AxiosRequestConfig) {
		return instance.get(route, config).then((response: any) => {
			return response.data;
		});
	},
	post(route: string, payload?: FormData | { [idx: string]: any }, shouldNotify: boolean = true) {
		return instance.post(route, payload).then((response: any) => {
			if (shouldNotify) notify('success', response.data.message);

			return response.data;
		});
	},
	update(route: string, payload: FormData | { [idx: string]: any }, shouldNotify: boolean = false) {
		return instance.put(route, payload).then((response: any) => {
			if (shouldNotify) notify('success', response.data.message || 'Updated successfully');

			return response.data;
		});
	},
	// delete(route: string, shouldNotify: boolean = true) {
	// 	return instance.delete(route).then((response: any) => {
	// 		if (shouldNotify) notify('success', response.data.message || 'Removed successfully');

	// 		return response.data;
	// 	});
	// },
	// put(route: string, payload: object, shouldNotify: boolean = true) {
	// 	return instance.put(route, payload).then((response: any) => {
	// 		if (shouldNotify) notify('success', 'Updated successfully');

	// 		return response;
	// 	});
	// },
	// patch(route: string, payload: FormData | { [idx: string]: any }, shouldNotify: boolean = true) {
	// 	return instance.patch(route, payload).then((response: any) => {
	// 		if (shouldNotify) notify('success', response.data.message || 'Updated Successfully');

	// 		return response.data;
	// 	});
	// },
};

const clearAuth = (forced = false) => {
	delete instance.defaults.headers.common['Authorization'];
	delete instance.defaults.headers.common['X-Admin-Institution'];

	store.dispatch(logout());

	// Notify
	forced ? notify('error', 'Session Expired') : notify('info', 'Logged out');
};

export default apiClient;
