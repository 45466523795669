import { useSelector } from "react-redux";
import { RootState } from "../types";
import currencies from '../utils/currencies.json';
import store from '../redux/store';

export const getCurrency = () => {
    // Get the current state from the store
    const state: RootState = store.getState();
    // Get the currency code from the state
    const currencyCode = state.auth?.user?.currency;
    // Find the currency object based on the currency code
    const currency = currencies.find((currency: any) => currency.cc === currencyCode);

    const currencySymbol = currency?.symbol;

    console.log(state, currencyCode, currency, currencySymbol);

    return currencySymbol;
}