import React from 'react';
import styled from 'styled-components';
import inventoryImage from '../assets/inventory.png';
import analyticsImage from '../assets/tutorial-slide/analytics.png';
import orderManagementImage from '../assets/tutorial-slide/orderManagement.png';
import stockManagementImage from '../assets/tutorial-slide/stockManagement.png';
import productManagementImage from '../assets/tutorial-slide/productManagement.png';
import "../login.css"


const Wrapper = styled.div`display:flex; flex-direction:column; align-items:center; min-height:100vh;width:100vw;background-color:#E5F8FF; padding:100px 0px;
@media (max-width: 768px){padding:50px 0px}`;
const Title = styled.h1`font-size:40px; font-weight:700; margin-top:30px; margin-bottom:10px;text-align:center; width:100%; font-family:'Inter', sans-serif;
@media (max-width: 768px){ margin-top:0px;font-size:25px }`;
const Description = styled.p`font-size:23px; margin-bottom:80px;text-align:center; width:80%;font-weight:300; font-family: 'Poppins', sans-serif;
@media (max-width: 768px){font-size:15px; margin-bottom:20px; width:90%}`;
const TutorialSection = styled.div`display:flex; flex-direction:row; width:90%;flex:1;
@media (max-width: 768px){flex-direction:column-reverse;flex:0}`;
const LeftPanel = styled.div`width:55%;min-height:100%;background-color:#E5F8FF;display:flex;flex-direction:column;padding-right:50px;
@media (max-width: 768px){ width:100%; padding-right:0px;margin-top:20px }`;
const RightPanel = styled.div`width:45%;min-height:100%;display:flex;flex-direction:column;
@media (max-width: 768px){ width:100%}`;
const TutorialItemWrapper = styled.div`border-bottom:1px solid #92CDF1; padding:20px 0px; cursor:pointer;
@media (max-width: 768px){padding:15px 0px}`;
const TutorialItemTitle = styled.h1`font-size:28px; font-family: 'Poppins', sans-serif; font-weight:400;
@media (max-width: 768px){font-size:18px}`;
const TutorialItemDescription = styled.p`font-size:18px; font-family: 'Poppins', sans-serif; font-weight:300; height:65px; overflow:hidden; transition:200ms;
@media (max-width: 768px){font-size:15px}`;


function TutorialItem({ title, description, isExpanded, handleClick }: { title: string, description: string, isExpanded: boolean, handleClick: () => void }) {
    return (
        <TutorialItemWrapper className="tutorial-item-wrapper" onClick={handleClick}>
            <TutorialItemTitle>{title}</TutorialItemTitle>
            <TutorialItemDescription style={{ height: isExpanded ? "65px" : "0px" }}>{description}</TutorialItemDescription>
        </TutorialItemWrapper>
    )
}
export default function MarketingSection() {

    const [currentSlide, setCurrentSlide] = React.useState(0);

    const slides = [
        {
            title: "Manage Inventory",
            description: "Keep track of the stock you currently have available for each of your products",
        },
        {
            title: "Manage Products",
            description: "List out all products that you offer and set their default price and minimum stock quantity"
        },
        {
            title: "Business Insights",
            description: "Gain insights into your business with a summary of sales, expenses, top-performing products, and stock availability.",
        },
        {
            title: "Manage Orders",
            description: "Simplify order creation and track order details, including customer information and product-specific sales data."
        },
        {
            title: "Track Stocking History",
            description: "Keep track of when a products are stocked so you can stay on top of your inventory."
        },

    ];

    const slideImages = [
        inventoryImage,
        productManagementImage,
        analyticsImage,
        orderManagementImage,
        stockManagementImage,
    ]

    return (
        <Wrapper>
            <Title>Why VendReady</Title>
            {/* <Description>Our platform is designed to help you manage your business with ease. We provide tools to help you manage your orders, track your expenses, and analyze your sales data.</Description> */}
            <Description>VendReady is a simple sales and inventory tracking app that helps businesses monitor orders, manage stock, and gain valuable insights into their operations.</Description>
            <TutorialSection>
                <LeftPanel>
                    <img src={slideImages[currentSlide]} style={{ width: '100%', objectFit: 'contain' }} />
                    {/* <div style={{ backgroundColor: 'green', width: '80%', height: '150px' }}></div> */}
                </LeftPanel>
                <RightPanel>
                    {
                        slides && slides.map((slide, index) => {
                            return (
                                <TutorialItem title={slide.title} description={slide.description} isExpanded={currentSlide === index} handleClick={() => setCurrentSlide(index)} />
                            )
                        })
                    }
                </RightPanel>
            </TutorialSection>
        </Wrapper>
    );
}