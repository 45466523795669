import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { GridColDef } from '@mui/x-data-grid';
import '../index.css';
import Table from '../components/Table';
import ProductModal from '../components/ProductModal';
import { ProductInterface } from '../types';
import moment from 'moment';
import apiClient from '../services/apiClient';
import EmptyState from '../components/EmptyState';
import ProductIcon from '../assets/svgs/ProductIcon';
import ReactGA from 'react-ga4';

const Wrapper = styled.div`
    background-color:#f3f4f8;
    width:100%;
    min-height:100vh;
    padding:20px 40px;
    box-sizing:border-box;
    @media (max-width: 768px) {
        padding:20px 20px;
    }
`;

const CreateButton = styled.button`
    background-color:#0061FF;
    border:none;
    border-radius:10px;
    color:white;
    padding:8px 18px;
    font-size:15px;
    transition:250ms;
    cursor:pointer;
    &:hover{
        background-color:#0053D9;
        color:#BFDFFE;
    }
`

export default function Products() {
    const [isEditPanelVisible, setIsEditPanelVisible] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductInterface | null>(null);
    const [products, setProducts] = useState<ProductInterface[]>([]);
    const [fetchState, setFetchState] = useState<'loading' | 'success' | 'failed'>('loading');

    const columns: (GridColDef & { isNotSearchable?: boolean })[] = [
        { field: 'name', headerName: 'Name', minWidth: 260, headerClassName: "table-header", cellClassName: 'bold-text', flex: 2 },
        { field: 'default_price', headerName: 'Default Price', minWidth: 90, headerClassName: "table-header", cellClassName: 'thin-text', valueGetter: (params) => Number(params.value).toFixed(2), flex: 1 },
        { field: 'min_stock_level', headerName: 'Min Stock Qty', minWidth: 90, headerClassName: "table-header", cellClassName: 'thin-text', flex: 1 },
        { field: 'date_created', headerName: 'Date Created', minWidth: 150, headerClassName: "table-header", cellClassName: 'thin-text', renderCell: (params) => moment(params.value).format('Do MMM YYYY'), flex: 1 },
        { field: 'date_updated', headerName: 'Last Updated', minWidth: 150, headerClassName: "table-header", cellClassName: 'thin-text', renderCell: (params) => moment(params.value).format('Do MMM YYYY'), flex: 1 },
    ];

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/products",
            title: "Products",
        });
        setFetchState('loading');
        apiClient.get('/products').then((response: any) => {
            setFetchState('success');
            console.log("fetched products", response.data);
            setProducts(response.data);
            ReactGA.event({
                category: "Products",
                action: "Fetched Products",
                label: "Fetch Success",
            });
        }).catch((error) => {
            console.log("error fetching products", error);
            setFetchState('failed');
            ReactGA.event({
                category: "Products",
                action: "Fetch Products Failed",
                label: "Fetch Failed",
            });
        })

    }, [])

    useEffect(() => {
        console.log("product updated", products);
    }, [products])

    const handleRowClick = (product: ProductInterface) => {
        ReactGA.event({
            category: "User Interaction",
            action: "Clicked Product Row",
            label: `Product ID: ${product.id}`,
        });
        setSelectedProduct(product);
        setIsEditPanelVisible(true);
    }

    const showCreateProductPanel = () => {
        ReactGA.event({
            category: "User Interaction",
            action: "Clicked Add New Product Button",
            label: "Add New Product",
        });
        setIsEditPanelVisible(true)
    }

    const hideCreateProductPanel = (product: ProductInterface | null = null) => {
        setIsEditPanelVisible(false);
        setSelectedProduct(null);

        if (product) {
            let isProductNew = true;

            setProducts(products.map((p: ProductInterface) => {
                if (p.id === product.id) {
                    isProductNew = false;
                    return product;
                }
                return p;
            }))

            if (isProductNew) {
                setProducts([...products, product]);
                ReactGA.event({
                    category: "Products",
                    action: "Created New Product",
                    label: `Product ID: ${product.id}`,
                });
            } else {
                ReactGA.event({
                    category: "Products",
                    action: "Updated Product",
                    label: `Product ID: ${product.id}`,
                });
            }
        } else {
            ReactGA.event({
                category: "User Interaction",
                action: "Closed Product Panel",
                label: "No Product",
            });
        }
    }

    return (
        <Wrapper>
            <Header
                title="Products"
                description="Create, edit, or delete products here"
                headerComponents={[<CreateButton onClick={showCreateProductPanel}>Add New</CreateButton>]}
            />
            <Table
                columns={columns}
                objects={products}
                sx={{ marginTop: '50px' }}
                onRowClick={(param: any) => handleRowClick(param.row)}
                emptyStateComponent={() => (
                    <EmptyState
                        handleButtonClick={showCreateProductPanel}
                        title="No Products Yet"
                        description="Start by creating your first product. Once added, you can manage and track your products on the inventory page."
                        buttonText="Add New Product"
                        icon={<ProductIcon width={50} height={50} />}
                    />
                )}
                fetchState={fetchState}
            />
            <ProductModal
                isVisible={isEditPanelVisible}
                handleHidePanel={hideCreateProductPanel}
                product={selectedProduct}
            />
        </Wrapper>
    )
}