import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import * as routes from '../utils/routes';
import { RegisterFields } from '../types';
import apiClient from '../services/apiClient';
import { CircularProgress, Divider } from '@mui/material';
import MarketingSection from '../components/MarketingSection';
import downArrowImage from "../assets/down-arrow.png";
import ReactGA from 'react-ga4';
import { CurrencyCode } from '../types/currencies';
import currencies from '../utils/currencies.json';
import { Select } from '../components/SharedComponents';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftPanel = styled.div`
  width: 50%;
  min-height: 100%;
  background-color: #e5f8ff;
  display: flex;
  flex-direction: column;
  padding: 50px;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightPanel = styled.div`
  width: 50%;
  min-height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;
    padding-bottom:50px;
  }
`;

const RegisterForm = styled.div`
  width: 80%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width:100% !important;
  }
`;

const InputField = styled.input`background-color:#F3F3F3;border:none;padding:15px;border-radius:8px;margin-bottom:5px;margin-top:5px`;

const Button = styled.button`
  padding: 15px;
  width: 100%;
  margin-top: 30px;
  border-radius: 8px;
  border: none;
  box-shadow: 1px 5px 2px 0px rgb(120 120 120 / 12%);
  background-color: black;
  color: white;
  cursor: pointer;
`;

const Logo = styled.p`
  font-size: 30px;
  margin-bottom: 50px;
  position: absolute;
  top: 30px;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Red Hat Display', sans-serif;
  color: black;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    `;

const RegisterFormDescriptionText = styled.p`width:80%; text-align:center; margin-bottom:120px;
@media (max-width: 768px){ margin-bottom:40px; }`;

const initialState: RegisterFields = {
    firstname: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
    shop_name: '',
    currency: CurrencyCode.USD
};

const LearnMoreButton = styled.button`background-color:#C7EAFF; padding:10px 30px;padding-right:20px; border-radius:20px; color:black; cursor:pointer; margin-top:20px;font-family:'Poppins', sans-serif; font-weight:500;font-size:14px; display:flex;flex-direction:row;align-items:center;`;

export default function Register() {
    const [isPosting, setIsPosting] = React.useState(false);
    const [registerFields, setLoginFields] = React.useState<RegisterFields>(initialState);
    const marketingSectionRef = React.useRef<HTMLDivElement>(null);

    const handleLearnMoreClick = () => {
        if (marketingSectionRef.current) {
            marketingSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navigate = useNavigate();

    React.useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/register",
            title: "Register",
        });
    }, []);

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsPosting(true);

        apiClient.auth.register(registerFields)
            .then(() => {
                setIsPosting(false);
                navigate(routes.DASHBOARD);
            })
            .catch(() => {
                setIsPosting(false);
            });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setLoginFields({ ...registerFields, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Wrapper>
                <LeftPanel>
                    <Logo>VendReady</Logo>
                    <p style={{ fontSize: '25px', lineHeight: '40px', width: '75%' }}>
                        Simplified sales and inventory management for the rest of us
                    </p>
                </LeftPanel>
                <RightPanel>
                    <RegisterForm>
                        <p style={{ fontSize: '30px', margin: '0px' }}>Create An Account</p>
                        <RegisterFormDescriptionText >
                            Create an account to start managing your inventory!
                        </RegisterFormDescriptionText>
                        <Row style={{ justifyContent: 'space-between' }}>
                            <FormGroup style={{ width: '48%' }}>
                                <InputField placeholder="First Name" type="text" onChange={handleChange} name="firstname" value={registerFields.firstname} />
                            </FormGroup>
                            <FormGroup style={{ width: '48%' }}>
                                <InputField placeholder="Last Name" type="text" onChange={handleChange} name="surname" value={registerFields.surname} />
                            </FormGroup>
                        </Row>
                        <FormGroup>
                            <InputField placeholder="Email Address" type="email" onChange={handleChange} name="email" value={registerFields.email} />
                        </FormGroup>
                        <FormGroup>
                            <InputField placeholder="Shop/Business Name" type="text" onChange={handleChange} name="shop_name" value={registerFields.shop_name} autoComplete='off' />
                        </FormGroup>
                        <FormGroup>
                            <Select name="currency" value={registerFields.currency} onChange={(e) => handleChange(e)} style={{ padding: '15px' }}>
                                {currencies.map((currency: any, currencyIdx: number) => (
                                    <option value={currency.cc} key={currency.cc}>{currency.name} ({currency.symbol})</option>
                                ))}
                            </Select>
                        </FormGroup>
                        <Divider style={{ width: '50%', margin: '30px 0px' }} variant="inset" />
                        <FormGroup>
                            <InputField placeholder="Password" type="password" onChange={handleChange} name="password" value={registerFields.password} autoComplete='new-password' />
                        </FormGroup>
                        <FormGroup>
                            <InputField placeholder="Retype Password" type="password" onChange={handleChange} name="confirmPassword" value={registerFields.confirmPassword} autoComplete='new-password' />
                            {registerFields.password !== registerFields.confirmPassword && <p style={{ color: 'red', fontSize: '12px' }}>Passwords do not match</p>}
                        </FormGroup>
                        <Button onClick={handleSubmit}>
                            {isPosting ? <CircularProgress size={15} color="inherit" /> : "Register"}
                        </Button>
                        <span style={{ fontSize: '13px', marginTop: '10px' }}>
                            Already have an account? <Link to={routes.LOGIN} style={{ color: 'blue' }}>Login</Link>
                        </span>
                    </RegisterForm>
                    <LearnMoreButton onClick={handleLearnMoreClick}>Learn more <img src={downArrowImage} style={{ height: '16px', marginLeft: '7px' }} /></LearnMoreButton>
                </RightPanel>
            </Wrapper>
            <div ref={marketingSectionRef}>
                <MarketingSection />
            </div>
        </>
    );
}
