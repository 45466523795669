import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import VerifyEmail from '../pages/VerifyEmail';

const PrivateRoutes = () => {
    const token = useSelector((state: RootState) => state.auth?.user?.token);
    const is_email_verified = useSelector((state: RootState) => state.auth?.user?.is_email_verified);
    const email = useSelector((state: RootState) => state.auth?.user?.email);

    return (token ? (
        is_email_verified ? <Outlet /> : <Navigate to={`/verify-email?email=${email}`} />
    ) : <Navigate to="/login" />);

};

export default PrivateRoutes;