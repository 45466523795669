import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import * as routes from '../utils/routes';
import apiClient from '../services/apiClient';
import notify from '../services/notify';
import { CircularProgress } from '@mui/material';
import ReactGA from 'react-ga4';

const Wrapper = styled.div`height:100vh; display:flex;flex-direction:row;align-items:center;justify-content:center; background-color:#E5F8FF;padding:10px;`;
const ForgottenPasswordForm = styled.div`background-color:white; width:500px; display:flex;flex-direction:column; align-items:center; border:1px solid #e5e5e5;padding:50px; border-radius:8px;box-shadow: 2px 4px 7px rgb(0 0 0 / 7%);`
const FormGroup = styled.div`width:100%;display:flex;flex-direction:column;`;
const InputField = styled.input`background-color:#F3F3F3;border:none;padding:20px;border-radius:8px;margin-bottom:10px;margin-top:5px`;
const Label = styled.label`font-size:13px; padding-left:2px`;
const Button = styled.button`padding:20px;width:100%;margin-top:10px;border-radius:8px;border:none;box-shadow:1px 5px 2px 0px rgb(120 120 120 / 12%); background-color:black; color:white;cursor:pointer`
const Logo = styled.p`font-size:30px;font-family: 'Noto Sans', sans-serif;font-family: 'Red Hat Display', sans-serif;position:absolute;top:30px`;
const Title = styled.p`font-size:25px;margin-bottom:20px`;

const postPasswordResetRequest = (email: string) => {
    return apiClient.get(`/users/request-password-reset?email=${email}`);
}

export default function ForgottenPassword() {

    const [email, setEmail] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/forgotten-password",
            title: "Forgotten Password",
        });
    }, []);



    const requestPasswordReset = () => {
        setIsLoading(true);
        postPasswordResetRequest(email)
            .then((response) => {
                notify("info", "Password reset instructions sent to your email");
                navigate(routes.LOGIN);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Wrapper>
            <Logo>Vend<span style={{ color: '#43BEF7' }}>Ready</span></Logo>
            <ForgottenPasswordForm>
                <Title style={{ marginBottom: '5px' }}>Forgotten Password</Title>
                <p style={{ fontSize: '13px', marginBottom: '40px' }}>
                    Please enter the email you registered with.
                    An email with instructions on how to reset your password will be sent to that address.
                </p>
                <FormGroup>
                    <Label>Email</Label>
                    <InputField type="email" placeholder='Email address' value={email} onChange={handleChange} />
                </FormGroup>
                <Button onClick={requestPasswordReset} disabled={isLoading}>{isLoading ? <CircularProgress size={15} color="inherit" /> : "Request Password Reset"}</Button>
                <span style={{ fontSize: '13px', marginTop: '10px' }}>
                    Back to <Link to={routes.LOGIN} style={{ color: 'blue' }}>Login</Link>
                </span>
            </ForgottenPasswordForm>
        </Wrapper>
    )
}