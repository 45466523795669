import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import apiClient from '../services/apiClient';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as routes from '../utils/routes';
import ReactGA from 'react-ga4';


const Wrapper = styled.div`height:100vh; display:flex;flex-direction:row;align-items:center;background-color:#E5F8FF;justify-content:center`;
const ForgottenPasswordForm = styled.div`background-color:white; width:500px; display:flex;flex-direction:column; align-items:center; border:1px solid #e5e5e5;padding:50px; border-radius:8px;box-shadow: 2px 4px 7px rgb(0 0 0 / 7%);`
const FormGroup = styled.div`width:100%;display:flex;flex-direction:column;`;
const InputField = styled.input`background-color:#F3F3F3;border:none;padding:20px;border-radius:8px;margin-bottom:10px;`;
const Button = styled.button`
  padding: 20px;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  border: none;
  box-shadow: 1px 5px 2px 0px rgb(120 120 120 / 12%);
  background-color: black;
  color: white;
  cursor: pointer;
  transition: 250ms;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    box-shadow: none;
  }
`;
const Logo = styled.p`font-size:30px;font-family: 'Noto Sans', sans-serif;font-family: 'Red Hat Display', sans-serif;position:absolute;top:30px`;
const Title = styled.p`font-size:25px;margin-bottom:20px`;

const postPasswordReset = (data: { token: string, new_password: string }) => {
    return apiClient.post(`/users/change-password`, data);
}

export default function ResetPassword() {
    const [password, setPassword] = React.useState<string>('');
    const [retypePassword, setRetypePassword] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPasswordMatch, setIsPasswordMatch] = React.useState<boolean>(false);
    const { token } = useParams<{ token: string }>();

    const navigate = useNavigate();

    useEffect(() => {
        if (password === retypePassword) {
            setIsPasswordMatch(true);
        }

        if (password !== retypePassword) {
            setIsPasswordMatch(false);
        }
    }, [password, retypePassword])

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: `/reset-password/${token}`,
            title: "Reset Password",
        });
    }, []);

    const changePassword = () => {

        if (token && password) {
            setIsLoading(true);

            postPasswordReset({ token, new_password: password })
                .then((response) => {
                    console.log(response);
                    navigate(routes.LOGIN);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'password') {
            setPassword(e.target.value);
        }

        if (e.target.name === 'new_password') {
            setRetypePassword(e.target.value);
        }
    }

    return (
        <Wrapper>
            <Logo>Vend<span style={{ color: '#43BEF7' }}>Ready</span></Logo>
            <ForgottenPasswordForm>
                <Title>Reset Password</Title>
                <FormGroup>
                    <InputField type="password" name="password" placeholder='New Password' onChange={handleChange} />
                </FormGroup>
                <FormGroup style={{ marginBottom: '0px' }}>
                    <InputField type="password" name="new_password" placeholder='Retype Password' onChange={handleChange} />
                </FormGroup>
                {
                    !isPasswordMatch && <p style={{ color: 'red', fontSize: '13px' }}>Passwords do not match</p>
                }
                <Button onClick={changePassword} disabled={isLoading || !isPasswordMatch || !password || !retypePassword}>{isLoading ? <CircularProgress size={15} color="inherit" /> : "Update Password"}</Button>
                <span style={{ fontSize: '13px', marginTop: '10px' }}>
                    Back to <Link to={routes.LOGIN} style={{ color: 'blue' }}>Login</Link>
                </span>
            </ForgottenPasswordForm>
        </Wrapper>
    )
}