import React from "react";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
//   height: 100%;
  padding: 16px;
  text-align: center;
`;

const Card = styled.div`
  max-width: 400px;
  padding: 24px;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   border-radius: 16px;
  background-color: #fff;
`;

const IconWrapper = styled.div`
  color: #3f51b5;
  margin-bottom: 16px;
  display:flex;
  flex-direction:row;
    justify-content:center;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Description = styled.p`
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 24px;
`;

const EmptyState: React.FC<{ handleButtonClick: () => void, title: string, description: string, buttonText: string, icon?: any }> = ({ handleButtonClick, title, description, buttonText, icon }) => {
    return (
        <Container>
            <Card>
                <IconWrapper>
                    {
                        icon ?? <AddCircleOutlineIcon style={{ fontSize: 48 }} />
                    }
                </IconWrapper>
                <Title>{title}</Title>
                <Description>
                    {description}
                </Description>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    fullWidth
                >
                    {buttonText}
                </Button>
            </Card>
        </Container>
    );
};

export default EmptyState;
