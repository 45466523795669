import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import * as routes from '../utils/routes';
import apiClient from '../services/apiClient';
import { LoginFields } from '../types';
import { CircularProgress } from '@mui/material';
import "../login.css"
import ordersScreenshot from "../assets/demo-slide/orders.png"
import analyticsScreenshot from "../assets/demo-slide/analytics.png"
import expensesScreenshot from "../assets/demo-slide/expenses.png"
import 'animate.css'
import MarketingSection from '../components/MarketingSection';
import downArrowImage from "../assets/down-arrow.png";
import ReactGA from 'react-ga4';




const Wrapper = styled.div`display:flex; flex-direction:row; min-height:100vh;`;
const LeftPanel = styled.div`width:55%;min-height:100%;background-color:#E5F8FF;display:flex;flex-direction:column;padding:50px; justify-content:center;
@media (max-width: 768px){ display:none; }`;
const RightPanel = styled.div`width:45%;min-height:100%;background-color:white;display:flex;flex-direction:column; align-items:center;justify-content:center;
@media (max-width: 768px){ width:100%; min-height:100vh;padding:60px 0px }`;
const RegisterForm = styled.form`width:80%;padding:30px;display:flex;flex-direction:column; align-items:center;justify-content:center;
@media (max-width: 768px){ width:95%; }`;
const FormGroup = styled.div`width:100%;display:flex;flex-direction:column;`;
const InputField = styled.input`background-color:#F3F3F3;border:none;padding:15px;border-radius:8px;margin-bottom:20px;margin-top:5px`;
const Label = styled.label`font-size:14px; padding-left:2px`;
const FormBottomRow = styled.div`width:100%;font-size:13px;display:flex;flex-direction;row; justify-content:space-between;`;
const Button = styled.button`padding:15px;width:100%;border-radius:8px;border:none;box-shadow:1px 5px 2px 0px rgb(120 120 120 / 12%); background-color:black; color:white;cursor:pointer; margin-bottom:20px`
const Logo = styled.p`font-size:30px; margin-bottom:50px; position:absolute;top:30px; font-family: 'Noto Sans', sans-serif; font-family: 'Red Hat Display', sans-serif; color:black;`;
const SlideText = styled.p`
    background-color: white;
    padding: 10px 30px;
    border-radius: 12px;
    display: inline-block;
    border: 1px solid #eaebf0;
    position: relative;
    top: 10px;
    left: -30px;
    z-index: 1;
`;
const LearnMoreButton = styled.button`background-color:#C7EAFF; padding:10px 30px;padding-right:20px; border-radius:20px; color:black; cursor:pointer; margin-top:20px;font-family:'Poppins', sans-serif; font-weight:500;font-size:14px; margin-top:50px; display:flex;flex-direction:row;align-items:center;`;

const initialState: LoginFields = {
    email: '',
    password: '',
}

export default function Login() {
    const [isPosting, setIsPosting] = React.useState(false);
    const [loginFields, setLoginFields] = React.useState<LoginFields>(initialState);
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [animate, setAnimate] = React.useState(false);

    const marketingSectionRef = React.useRef<HTMLDivElement>(null);

    const handleLearnMoreClick = () => {
        if (marketingSectionRef.current) {
            marketingSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navigate = useNavigate();

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsPosting(true);

        apiClient.auth.login(loginFields)
            .then(() => {
                setIsPosting(false)
                navigate(routes.DASHBOARD);
            })
            .catch(() => {
                setIsPosting(false);
            });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log("change", e.target.name, e.target.value)
        setLoginFields({ ...loginFields, [e.target.name]: e.target.value });
    };

    const slides = [
        {
            text: 'Manage customer orders',
            imgSrc: ordersScreenshot,
        },
        {
            text: 'Keep track of your business expenses',
            imgSrc: expensesScreenshot,
        },
        {
            text: 'Get a quick overview of monthly insights',
            imgSrc: analyticsScreenshot,
        },
    ];

    React.useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/login",
            title: "Login",
        });
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setAnimate(false); // Reset animation state
            setTimeout(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
                setAnimate(true); // Trigger animation
            }, 100); // Small delay to reset animation
        }, 6000); // Change slide every 8 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [slides.length]);


    return (
        <>
            <Wrapper>
                <LeftPanel>
                    <Logo>VendReady</Logo>
                    {slides.map((slide, index) => (
                        <div
                            key={index}
                            style={{
                                display: currentSlide === index ? 'block' : 'none',
                                width: '75%',
                                margin: '0px auto',
                            }}
                            className={`slide-${index + 1}`}
                        >
                            <SlideText className={animate ? 'animate__animated animate__fadeInUp' : ''}>
                                {slide.text}
                            </SlideText>
                            <img
                                src={slide.imgSrc}
                                className={animate ? 'animate__animated animate__fadeInUp animate__delay-0.5s' : ''}
                                style={{
                                    backgroundColor: '#f3f4f8',
                                    height: '430px', objectFit: 'contain',
                                    borderRadius: '8px',
                                    border: '1px solid #eaebf0',
                                }}
                                alt={slide.text}
                            />
                        </div>
                    ))}
                    <div>
                        <div className="slide-indicators">
                            {slides.map((_, index) => (
                                <div
                                    key={index}
                                    className={`slide-indicator ${currentSlide === index ? 'active' : ''}`}
                                />
                            ))}
                        </div>
                    </div>
                </LeftPanel>
                <RightPanel>
                    <RegisterForm>
                        <span className="login-header-sm" style={{ textAlign: 'center' }}>
                            <p className="logo" style={{ fontSize: '40px', margin: '0px', textAlign: 'center' }}>VendReady</p>
                            <p style={{ textAlign: 'center', marginBottom: "100px", color: 'grey', fontSize: '15px' }}>Login to track your sales and inventory</p>
                        </span>
                        <span className="login-header-lg" style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '40px', margin: '0px' }}>Welcome</p>
                            <p style={{ textAlign: 'center', marginBottom: "100px", }}>Login to start managing your inventory!</p>
                        </span>
                        <FormGroup>
                            <Label>Email address</Label>
                            <InputField type="email" name="email" onChange={handleChange} value={loginFields.email} />
                        </FormGroup>
                        <FormGroup >
                            <Label>Password</Label>
                            <InputField style={{ marginBottom: "10px" }} type="password" name="password" onChange={handleChange} value={loginFields.password} />
                        </FormGroup>
                        <FormBottomRow><div></div><Link style={{ color: 'blue' }} to={routes.FORGOTTEN_PASSWORD}>Forgotten Password</Link></FormBottomRow>
                        <Button onClick={handleSubmit} style={{ width: "100%", marginTop: '40px' }} disabled={isPosting}>{isPosting ? <CircularProgress size={15} color="inherit" /> : "Login"}</Button>
                        <span style={{ fontSize: '14px', marginTop: '10px' }}>
                            Don't have an account? <Link to={routes.REGISTER} style={{ color: 'blue' }}>Register</Link>
                        </span>
                    </RegisterForm>
                    <LearnMoreButton onClick={handleLearnMoreClick}>Learn more <img src={downArrowImage} style={{ height: '16px', marginLeft: '7px' }} /></LearnMoreButton>
                </RightPanel>
            </Wrapper >
            <div ref={marketingSectionRef}>
                <MarketingSection />
            </div>
        </>
    )
}