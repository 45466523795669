interface IconProps {
    width?: number;
    height?: number;
}


export default function InventoryIcon({ width = 20, height = 20 }: IconProps) {
    return (
        <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
        >
            <title />
            <g data-name="Layer 2" id="Layer_2">
                <path d="M27,29H9a1,1,0,0,1-1-1V16a1,1,0,0,1,2,0V27H26V6a1,1,0,0,0-1-1H7A1,1,0,0,1,7,3H25a3,3,0,0,1,3,3V28A1,1,0,0,1,27,29Z" />
                <path d="M9,17H5a1,1,0,0,1-1-1V6a3,3,0,0,1,6,0V16A1,1,0,0,1,9,17ZM6,15H8V6A1,1,0,0,0,6,6Z" />
                <path d="M19,19H13a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z" />
                <path d="M23,23H13a1,1,0,0,1,0-2H23a1,1,0,0,1,0,2Z" />
                <path d="M21,15H13a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z" />
                <path d="M23,8H13a1,1,0,0,1-1-1V4a1,1,0,0,1,1-1H23a1,1,0,0,1,1,1V7A1,1,0,0,1,23,8ZM14,6h8V5H14Z" />
            </g>
            <g id="frame">
                <rect fill="none" height="32" width="32" />
            </g>
        </svg>
    )
}